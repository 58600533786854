// Copyright 2024 Merit International Inc. All Rights Reserved

import { AlertContainer } from "@merit/frontend-components";
import {
  ApprovalsScreen,
  ConfigureTemplateScreen,
  CreateAppScreen,
  CreatePolicyScreen,
  CreateTemplateScreen,
  DataSourceListScreen,
  DatasourcesCreateScreen,
  DatasourcesScreen,
  DatasourcesUpdateScreen,
  EditFieldScreen,
  EditPolicyScreen,
  ExtendFieldScreen,
  FieldsScreen,
  LandingScreen,
  LoginFailure,
  LoginSuccess,
  LogoutSuccess,
  MapDataSourceScreen,
  NoPermissionsScreen,
  NoRolesScreen,
  OrgRegistrationScreen,
  OrgRegistrationSuccessScreen,
  OrgSelectScreen,
  OrgSettingsScreen,
  PlaceholderScreen,
  PoliciesScreen,
  RecordsScreen,
  RecordsSearchScreen,
  TemplatesScreen,
} from "./screens";
import { AuthChangedLogoutContainer } from "@src/AuthChangeLogoutContainer";
import { AutoMapTemplateScreen } from "./screens/AutoMapTemplate";
import { datadogRum as DdRum } from "@datadog/browser-rum";
import { EditCredentialScreen, StudioScreen } from "@src/studio/screens";
import { Helpers } from "@merit/frontend-utils";
import { NavigationContainer } from "@react-navigation/native";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReportsScreen } from "./screens/ReportsScreen";
import { Spin } from "./components";
import { View } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useAlertStore } from "./stores/alertStore";
import { useAuthStore } from "./stores/authStore";
import { useInitializeApp } from "./hooks/useInitializeApp";
import { useLoggedInUserRoles } from "./hooks/loggedInUserRoles";
import { useQueryErrorHandler } from "@src/studio/hooks";
import React, { useMemo } from "react";
import type { ListTemplates200ResponseTemplatesInnerTypeEnum } from "./gen/org-portal";
import type { OrgSettingsTabKey } from "./screens/OrgSettings/OrgSettings";
import type { TabKey } from "./screens/Templates/Templates";

export type PreLoginRouteParams = {
  readonly Landing: undefined;
  readonly LoginSuccessCallback: {
    readonly code: string;
    readonly state: string;
    readonly error?: string;
    readonly error_description?: string;
  };
  readonly OrgSelect: undefined;
  readonly LoginFailureCallback: undefined;
  readonly LoginError: undefined;
  readonly NoRoles: undefined;
};

export type PostLoginRouteParams = {
  // Screens
  readonly Account: undefined;
  readonly AddOnTools: undefined;
  readonly AppManagement: undefined;
  readonly Approvals: undefined;
  readonly Datasources: undefined;
  readonly DatasourceDetails: {
    readonly id: string;
  };
  readonly Fields: { readonly fieldId?: string } | undefined;
  readonly Notifications: undefined;
  readonly OrgSelect: undefined;
  readonly OrgSettings:
    | {
        readonly initialTab: OrgSettingsTabKey;
      }
    | undefined;
  readonly Overview: undefined;
  readonly PrivacyPolicy: undefined;
  readonly Records: undefined;
  readonly RecordDetails: {
    readonly templateType: ListTemplates200ResponseTemplatesInnerTypeEnum;
    readonly id: string;
  };
  readonly Studio: undefined;
  readonly EditCredential: { readonly credentialId: string };
  readonly Templates:
    | {
        readonly initialTemplateId?: string;
        readonly tab?: TabKey;
      }
    | undefined;
  readonly TermsOfService: undefined;
  readonly Verification: undefined;
  readonly NoPermissions: undefined;
  readonly Policies: undefined;
  readonly LogoutSuccess: undefined;

  // Modals
  readonly CreateField: undefined;
  readonly ExtendField: {
    readonly id: string;
  };
  readonly EditField: {
    readonly id: string;
  };
  readonly PlaceholderModal: undefined;
  readonly CreateApp: undefined;
  readonly CreateTemplate: undefined;
  readonly CreateDatasource: undefined;
  readonly ExtendTemplate: {
    readonly id: string;
  };
  readonly ConfigureTemplate: {
    readonly id: string;
  };
  readonly UpdateDatasource: {
    readonly id: string;
  };
  readonly SelectDataSource: {
    readonly templateName: string;
    readonly id: string;
  };
  readonly MapDataSource: {
    readonly datasourceID: string;
    readonly templateID: string;
  };
  readonly OrgRegistration: undefined;
  readonly OrgRegistrationSuccess: undefined;
  readonly CreatePolicy: undefined;
  readonly EditPolicy: {
    readonly id: string;
  };
  readonly ExtendPolicy: {
    readonly id: string;
  };
  readonly AutoMapTemplate: undefined;
  readonly Reports: undefined;
};

export type RouteParams = PostLoginRouteParams & PreLoginRouteParams;

// Map of route name to path (url on desktop)
export const LOGGED_OUT_ROUTES: Record<keyof PreLoginRouteParams, string> = {
  Landing: "/",
  LoginError: "/login-error",
  LoginFailureCallback: "/login-failure",
  LoginSuccessCallback: "/login-success",
  NoRoles: "/no-roles",
  OrgSelect: "/org-select",
};

export const LOGGED_IN_ROUTES: Record<keyof PostLoginRouteParams, string> = {
  Account: "/account",
  AddOnTools: "/add-on-tools",
  AppManagement: "/app-management",
  Approvals: "/approvals",
  AutoMapTemplate: "/automap-template",
  ConfigureTemplate: "/templates/:id/configure",
  CreateApp: "/apps/create",
  CreateDatasource: "/datasources/create",
  CreateField: "/fields/create",
  CreatePolicy: "/policies/create",
  CreateTemplate: "/templates/create",
  DatasourceDetails: "/datasources/:id/details",
  Datasources: "/datasources",
  EditCredential: "/studio/credential/:credentialId",
  EditField: "/fields/:id/edit",
  EditPolicy: "/policies/:id/edit",
  ExtendField: "/fields/:id/extend",
  ExtendPolicy: "/policies/:id/extend",
  ExtendTemplate: "/templates/:id/extend",
  Fields: "/fields",
  LogoutSuccess: "/logout-success",
  MapDataSource: "/templates/:templateID/datasources/:datasourceID/map",
  NoPermissions: "/no-permissions",
  Notifications: "/notifications",
  OrgRegistration: "/register",
  OrgRegistrationSuccess: "/registration-success",
  OrgSelect: "/org-select",
  OrgSettings: "/org-settings",
  Overview: "/overview",
  PlaceholderModal: "/modal",
  Policies: "/policies",
  PrivacyPolicy: "/privacy",
  RecordDetails: "/records/:templateType/:id/details",
  Records: "/records",
  Reports: "/reports",
  SelectDataSource: "/templates/:id/datasources",
  Studio: "/studio",
  Templates: "/templates",
  TermsOfService: "/terms-of-service",
  UpdateDatasource: "/datasources/:id/update",
  Verification: "/verification",
};

export const ROUTES: Record<keyof RouteParams, string> = {
  ...LOGGED_IN_ROUTES,
  ...LOGGED_OUT_ROUTES,
};

const OrgPortalNavigator = createNativeStackNavigator<RouteParams>();

const linking = {
  config: {
    screens: ROUTES,
  },
  prefixes: [""],
};

export const Navigator = () => {
  const { _hasHydrated, accessToken, selectedOrgId } = useAuthStore();
  const { LaunchDarklyProvider, isAppLoaded } = useInitializeApp();
  const { alertList } = useAlertStore();
  const onError = useQueryErrorHandler();
  const { isCheckinAdmin, isDashboardAdmin, isDesignAdmin, isOrgAdmin } = useLoggedInUserRoles();

  const initialScreenBasedOnRoles = useMemo(() => {
    if (isOrgAdmin) {
      return "OrgSettings";
    } else if (isDesignAdmin) {
      return "Studio";
    } else if (isDashboardAdmin) {
      return "Reports";
    } else if (isCheckinAdmin) {
      return "OrgSettings";
    }

    return "NoRoles";
  }, [isCheckinAdmin, isDashboardAdmin, isDesignAdmin, isOrgAdmin]);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 60 * 1000, // 5 mins
          },
        },
        queryCache: new QueryCache({
          onError,
        }),
      }),
    [onError]
  );
  if (!isAppLoaded || LaunchDarklyProvider === undefined || !_hasHydrated) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Spin />
      </View>
    );
  }

  const { Some } = Helpers;

  const isAuthenticated = accessToken !== null;
  const orgSelected = selectedOrgId !== null;
  const ifOrgSelectedRoute = orgSelected ? initialScreenBasedOnRoles : "OrgSelect";
  const initialRouteName = isAuthenticated ? ifOrgSelectedRoute : "Landing";

  const wrapResultScreens = (screens: JSX.Element): JSX.Element => (
    <LaunchDarklyProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        <NavigationContainer
          linking={linking}
          onStateChange={state => {
            if (Some(state) && Some(state.routes) && Some(state.index)) {
              DdRum.startView(state.routes[state.index].name);
            }
          }}
        >
          <OrgPortalNavigator.Navigator initialRouteName={initialRouteName}>
            {screens}
          </OrgPortalNavigator.Navigator>
          <AlertContainer alertList={alertList} />
          <AuthChangedLogoutContainer />
        </NavigationContainer>
      </QueryClientProvider>
    </LaunchDarklyProvider>
  );

  const noOrgSelectedScreens = () => (
    <OrgPortalNavigator.Group screenOptions={{ headerShown: false }}>
      <OrgPortalNavigator.Screen
        component={OrgSelectScreen}
        name="OrgSelect"
        options={{ title: "Choose an organization" }}
      />
      <OrgPortalNavigator.Screen
        component={NoPermissionsScreen}
        name="NoPermissions"
        options={{ title: "No Permissions" }}
      />
      <OrgPortalNavigator.Screen
        component={LogoutSuccess}
        name="LogoutSuccess"
        options={{ title: "Logout Success" }}
      />
      {/* this needs to be in noOrgSelectScreens to support org switching via auth0 after logging in */}
      <OrgPortalNavigator.Screen
        component={LoginSuccess}
        name="LoginSuccessCallback"
        options={{ title: "Login Success" }}
      />
    </OrgPortalNavigator.Group>
  );

  const loggedInScreens = () => (
    <OrgPortalNavigator.Group screenOptions={{ headerShown: false }}>
      <OrgPortalNavigator.Screen component={ApprovalsScreen} name="Approvals" />
      <OrgPortalNavigator.Screen component={FieldsScreen} name="Fields" />
      <OrgPortalNavigator.Screen
        component={DatasourcesScreen}
        name="Datasources"
        options={{ title: "Data Sources" }}
      />
      {/* this needs to be in loggedInScreens to support org switching via auth0 after logging in */}
      <OrgPortalNavigator.Screen
        component={LoginSuccess}
        name="LoginSuccessCallback"
        options={{ title: "Login Success" }}
      />
      <OrgPortalNavigator.Screen
        component={OrgSelectScreen}
        name="OrgSelect"
        options={{ title: "Choose an organization" }}
      />
      <OrgPortalNavigator.Screen component={DatasourcesScreen} name="DatasourceDetails" />
      <OrgPortalNavigator.Screen component={PlaceholderScreen} name="Overview" />
      <OrgPortalNavigator.Screen
        component={PlaceholderScreen}
        name="PrivacyPolicy"
        options={{ title: "Privacy Policy" }}
      />
      <OrgPortalNavigator.Screen
        component={TemplatesScreen}
        initialParams={{ tab: "owned" }}
        name="Templates"
      />
      <OrgPortalNavigator.Screen component={RecordsSearchScreen} name="Records" />
      <OrgPortalNavigator.Screen
        component={RecordsScreen}
        name="RecordDetails"
        options={{ title: "Record Details" }}
      />
      <OrgPortalNavigator.Screen component={PoliciesScreen} name="Policies" />
      <OrgPortalNavigator.Screen
        component={PlaceholderScreen}
        name="TermsOfService"
        options={{ title: "Terms of Service" }}
      />
      <OrgPortalNavigator.Screen component={PlaceholderScreen} name="Verification" />
      <OrgPortalNavigator.Screen
        component={OrgSettingsScreen}
        initialParams={{ initialTab: isCheckinAdmin && !isOrgAdmin ? "linkedApps" : undefined }}
        name="OrgSettings"
        options={{ title: "Organization Settings" }}
      />
      <OrgPortalNavigator.Screen
        component={OrgRegistrationScreen}
        name="OrgRegistration"
        options={{ title: "Register your organization" }}
      />
      <OrgPortalNavigator.Screen
        component={OrgRegistrationSuccessScreen}
        name="OrgRegistrationSuccess"
      />
      <OrgPortalNavigator.Screen
        component={CreatePolicyScreen}
        name="CreatePolicy"
        options={{ title: "Create Policy" }}
      />
      <OrgPortalNavigator.Screen component={StudioScreen} name="Studio" />
      <OrgPortalNavigator.Screen component={ReportsScreen} name="Reports" />
      <OrgPortalNavigator.Screen
        component={EditCredentialScreen}
        name="EditCredential"
        options={{ title: "Create Credential" }}
      />
      <OrgPortalNavigator.Screen
        component={LogoutSuccess}
        name="LogoutSuccess"
        options={{ title: "Logout Success" }}
      />
      <OrgPortalNavigator.Screen component={AutoMapTemplateScreen} name="AutoMapTemplate" />
      <OrgPortalNavigator.Screen component={NoRolesScreen} name="NoRoles" />
    </OrgPortalNavigator.Group>
  );

  const loggedInModals = () => (
    <OrgPortalNavigator.Group screenOptions={{ headerShown: false, presentation: "modal" }}>
      <OrgPortalNavigator.Screen
        component={DatasourcesCreateScreen}
        name="CreateDatasource"
        options={{ title: "Create Data Source" }}
      />
      <OrgPortalNavigator.Screen
        component={CreateAppScreen}
        name="CreateApp"
        options={{ title: "Create Linked App" }}
      />
      <OrgPortalNavigator.Screen
        component={CreateTemplateScreen}
        name="CreateTemplate"
        options={{ title: "Create Template" }}
      />
      <OrgPortalNavigator.Screen
        component={ConfigureTemplateScreen}
        name="ConfigureTemplate"
        options={{ title: "Configure Template" }}
      />
      <OrgPortalNavigator.Screen
        component={DatasourcesUpdateScreen}
        name="UpdateDatasource"
        options={{ title: "Update Data Source" }}
      />
      <OrgPortalNavigator.Screen
        component={CreateTemplateScreen}
        name="ExtendTemplate"
        options={{ title: "Extend Template" }}
      />
      <OrgPortalNavigator.Screen
        component={DataSourceListScreen}
        name="SelectDataSource"
        options={{ title: "Select Data Source" }}
      />
      <OrgPortalNavigator.Screen
        component={MapDataSourceScreen}
        name="MapDataSource"
        options={{ title: "Map Data Source" }}
      />
      <OrgPortalNavigator.Screen
        component={ExtendFieldScreen}
        name="ExtendField"
        options={{ title: "Extend Field" }}
      />
      <OrgPortalNavigator.Screen
        component={ExtendFieldScreen}
        name="CreateField"
        options={{ title: "Create Field" }}
      />
      <OrgPortalNavigator.Screen
        component={EditFieldScreen}
        name="EditField"
        options={{ title: "Edit Field" }}
      />
      <OrgPortalNavigator.Screen
        component={EditPolicyScreen}
        name="EditPolicy"
        options={{ title: "Edit Policy" }}
      />
      <OrgPortalNavigator.Screen
        component={EditPolicyScreen}
        name="ExtendPolicy"
        options={{ title: "Extend Policy" }}
      />
    </OrgPortalNavigator.Group>
  );

  const loggedOutScreens = () => (
    <OrgPortalNavigator.Group screenOptions={{ headerShown: false }}>
      <OrgPortalNavigator.Screen component={LandingScreen} name="Landing" />
      <OrgPortalNavigator.Screen
        component={LoginSuccess}
        name="LoginSuccessCallback"
        options={{ title: "Login Success" }}
      />
      <OrgPortalNavigator.Screen
        component={LoginFailure}
        name="LoginFailureCallback"
        options={{ title: "Login Failure" }}
      />
    </OrgPortalNavigator.Group>
  );

  // this mess of branches is used to prevent evaluating LoggedOutScreens twice
  if (isAuthenticated) {
    if (orgSelected) {
      // authenticated and have selected an org
      return wrapResultScreens(
        <>
          {loggedInScreens()}
          {loggedInModals()}
        </>
      );
    }

    // authenticated but have not selected an org
    return wrapResultScreens(<>{noOrgSelectedScreens()}</>);
  }

  // not authenticated
  return wrapResultScreens(<>{loggedOutScreens()}</>);
};
