import { useAppConstantsStore, useAuthStore } from "@src/stores";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

export const useLoggedInUserRoles = () => {
  // Not using useLoggedInAuthState because of the asserts and this hook may be
  // used in the Router component, where there are code branches that the user is
  // not logged in
  const { profile } = useAuthStore();
  const { adminRoleNames } = useAppConstantsStore();
  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();

  return useMemo(() => {
    const userRoles = profile === null ? [] : profile.userRoles;

    return rolloutAdminPortalAuth0OrgAuth
      ? {
          isCheckinAdmin: Boolean(userRoles.includes(adminRoleNames.checkinAdmin)),
          isDashboardAdmin: Boolean(userRoles.includes(adminRoleNames.dashboardAdmin)),
          isDesignAdmin: Boolean(userRoles.includes(adminRoleNames.designAdmin)),
          isOrgAdmin: Boolean(userRoles.includes(adminRoleNames.orgAdmin)),
          userRoles,
        }
      : {
          isCheckinAdmin: true,
          isDashboardAdmin: true,
          isDesignAdmin: true,
          isOrgAdmin: true,
          userRoles, // this is only used for display purposes, it's better to leave it empty when the user has no roles.
        };
  }, [
    adminRoleNames.checkinAdmin,
    adminRoleNames.dashboardAdmin,
    adminRoleNames.designAdmin,
    adminRoleNames.orgAdmin,
    profile,
    rolloutAdminPortalAuth0OrgAuth,
  ]);
};
