import { useAppConstantsStore } from "@src/stores";
import { useOrgAddons } from "./useOrgAddOns";
import type { AdminRoleNamesValue, OrgAddOnNamesValue } from "@src/stores";

type UseOrgAllowedAdminRolesReturn = {
  readonly isLoading: boolean;
  readonly roles: readonly AdminRoleNamesValue[];
};

export const useOrgAllowedAdminRoles = (): UseOrgAllowedAdminRolesReturn => {
  const { adminRoleNames, adminRoleNamesSortedByImportance, orgAddOnToAdminRoleMap } =
    useAppConstantsStore();
  const { addOns, isLoading } = useOrgAddons();
  if (isLoading) {
    return {
      isLoading,
      roles: [],
    };
  }

  const addOnRoles = addOns
    .map(ao => orgAddOnToAdminRoleMap[ao as unknown as OrgAddOnNamesValue])
    .filter(ar => ar !== undefined);
  const resolvedAdminRoles = adminRoleNamesSortedByImportance.filter(
    // Org Admin is a default option, it's not tied to an addOn
    ar => ar === adminRoleNames.orgAdmin || addOnRoles.includes(ar)
  );

  return {
    isLoading: false,
    roles: resolvedAdminRoles,
  };
};
